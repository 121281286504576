html,
body,
header {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  font-size: x-large;
}

p {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.DivisionLine {
  margin-top: 2%;
  margin-bottom: 2%;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.col {
  padding: 0% !important;
}

.row {
  margin: 0% !important;
}

.titleImg {
  width: 100%;
  height: auto;
  margin-left: 50%;
  transform: translateX(-50%);
  aspect-ratio: 5 / 2;
}

.titleContainer {
  position: relative;
  text-align: center !important;
  overflow: hidden;
  max-height: 580px;
}

.footer-container {
  background-color: #150941;
  width: 100%;
  margin: auto;
  bottom: 0;
  left: 0;
}

.footer-content p {
  color: white;
  text-align: center;
  font-size: 0.8em;
  margin-top: 0.5%;
  margin-bottom: 0.5%;

}

.copyright p {
  color: white;
  text-align: center;
  font-size: 0.7em;
}

.socials-links {
  display: flex;
  justify-content: center;
  width: 40%;
  margin: auto;
}

.socials-links  i {
  color: white;
  font-size: 2.3em;
  padding: 0.2em;
}

.app_img h6 {
  text-align: right;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  font-size: 0.7em;
  line-height: 1.5em;
  margin: 0%;
}

.app_image {
  width: 100%;
  margin-left: 0%;
  padding-top: 10px;
  transform: translateX(0%);
}

.app_img {
  text-align: center;
  overflow: hidden;
  max-width: 600px;
  max-height: 350px;

}

.rounded {
  float: left;
}

.marketing {
  background: white;
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 1%;
  padding: 3%;
}

.app_container {
  background-color: #E7EFF4;
  overflow: hidden;
}

.app_desc {
  margin-top: 2%;
}

.app_title h6 {
  font-size: 1em;
  margin-top: 2%;
  text-transform: uppercase;
}

.app_desc p {
  font-size: 0.7em;
  line-height: 1.6em;
  margin-right: 5%;
  text-align: left;
}

.app_desc img {
  padding: 15px;
  max-height: 400px;
}

.img-fluid {
  display: none;
}

.app_desc li {
  font-size: 1.2em;
  line-height: 1.6em;
  margin-right: 5%;
}

.app_bnt .btn, .app_bnt {
  color: white;
  background-color: #002940;
  border: none;
  border-radius: 5px;
  margin-bottom: 1%;
  margin-top: 5%;
  padding: 10px;
}

.app_bnt a {
  color: white;
  font-size: 0.8em;
  padding: 0px;
}

a {
  color: #002940;
  font-weight: bold;
}


.blockquote .mb-0 {
  font-size: 0.8em;
  color: #002940;
  padding-bottom: 10px;
}

.blockquote-footer {
  font-size: 0.6em;
  color: #002940;
}

.app_img h6 {
  text-align: right;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  font-size: 0.7em;
  line-height: 1.5em;
  margin: 0%;
}

.app_fulltext p {
  padding-top: 1%;
  font-size: 0.8em;
  line-height: 1.6em;
}

#contact_image {
  width: 200%;
  height: auto;
}

.img-contact {
  width: 100%;
  overflow: hidden;
  height:auto;
}

.form-group {
  width: 100% !important;
}

.form-group .input-group-text {
  background-color: #E0E8F6;
}

.form-group .fa {
  color:  #002940 !important;
  width: 50px;
}

.form-group h3 {
  font-size: 0.9em;
  margin: 0%;
  color: #002940;
}

.form-group .mb-2 {
  margin: 0% !important;
}

.form-group .form-control {
  margin-bottom: 0% !important;
  color: #002940;
  font-size: 0.9em;
  opacity: 0.8;
}

.submit {
  padding: 10px;
  border: none;
  background-color: #E0E8F6;
  width: 200px;
  font-weight: bold;
  text-align: center;
}

#landing {
  width: 100%;
  overflow:hidden;
  position: relative;
  text-align: center !important;
  max-height: 620px;
  margin-top: 10px;
}

.landingImg {
  width: 100%;
  height: auto;
  margin-left: 50%;
  transform: translateX(-50%);
  aspect-ratio: 5 / 2;
}

.image-container {
  text-align:center;
  width: 100%;
  position: absolute;
  top: 0%;
}

.image-container img {
  width: 700px;
  opacity: 0.7;
  margin-top: 6%;
  display: none

}

.motto-container {
  background: black;
  vertical-align: middle;
  position: absolute;
  top: 65%;
  width: 100%;
  opacity: 0.6;
  display:none;

}

.motto-container h5 {
  font-size: 30px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  color: white;
}

.logo-img {
  height: 250px;
  padding: 5em;
}

.banner-text {
  background-color: black;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.banner-text h1 {
  font-size: 60px;
  font-weight: bold;
  color: white;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.banner hr {
  border-top: dotted white;
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
}
.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}

.card-text {
  color: white;
  font-size: 1em;
  line-height: 1.2em;
}

.banner i {
  color: white;
  font-size: 4em;
  padding: 8px;
  text-align: center;
}

.banner h5 {
  color: white;
  font-size: 1.5em;
  line-height: 1.5em;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  margin-top: 1%;
}

.card {
  border: none;
  display: flex;
  width: 98%;
  height: 99%;
  background-color: #002940;
  margin: auto;
  border-radius: 0px;
  background-image: url(https://www.revontulicoding.fi/images/pictures/card_bg.jpg);
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 50%;

}

.landing-card {
  display: flex;
  flex-direction: row;
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 1%;
  padding: 1%;
  background-color: white;
}

.landing-container {
  background-image: url(https://www.revontulicoding.fi/images/pictures/readmyblog.jpg);
  background-size: cover;
  background-position-y: 50%;
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

.card-body {
  text-align: center;
}

.card-footer {
  text-align: center;
}

#marketing {
  width: 100%;
  height: 100%;
  background-color: #E7EFF4;
}

#main_cards {
  background-color: #E7EFF4;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

#main_blog {
  background-color: #E7EFF4;
}

.blog_li {
  position: relative;
  height: 100%;
  width: 98%;
  margin: auto;
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #002940;
}

.blog_li:hover .overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.wrapper_blog {
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 1%;
  padding: 1%;
  background-color: white;
}

.wrapper_blog h5 {
  font-size: 1em;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  text-align: center;
  text-transform: uppercase;
  margin-left: 2%;
}

.container-fluid {
  padding: 0%;
}

.container {
  max-width: 100%;

}

.navbar-collapse {
  background-color: #002940;
}
.nav-link  {
  color: white !important;
  text-align: center;
  vertical-align: middle;
  border-color:transparent;
  font-size: 1.4em;
  border:none;
  border-radius: 0;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding: 2px;
}

.nav-item {
  text-align: center;
  vertical-align: middle;
  display: grid;
}

.nav-item .btn {
  line-height: 1;
}

.navbar {
  background-color: #002940 !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding: 0px
}

.navbar-brand {
  text-align: center;
  /*width: 100%;*/
  margin: auto;
}

.navbar-brand img {
  width: 190px;
  height: auto;
}

.navbar-nav {
  margin: auto;
  text-align: center;
}

.navbar-nav button {
  background-color:transparent;
  font-weight: bold;
  border: none;
}


.dropdown-menu {
  background-color: #002940;
  margin: 0%;
}

.dropdown-item {
  color: white;
  font-size: 0.7em;
  text-align: center;
}

#hamburger {
  background-color: white;
  margin-right: 5%;
  padding: 1%;
  font-size: 1em;
  margin: auto;
}

/*backgrounbd*/
main,
section,
#intro {
  height: 100%;
}

.fixed-top {
  position: fixed;
}

.fixed-top2 {
  position: fixed;
  margin-top: 78px;
}

#button-blog {
  position: absolute;
  transform: translate(-50%,-50%);
  margin-right: -50%;
  top: 50%;
  left: 50%;

}

.landing-blog {
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 1%;
  padding: 1%;
  background-color: white;
}

.title-blog {

  /*bottom: 25%;*/
  font-size: 1.7em;
  color: white;
  text-shadow: 2px 2px black;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  text-align: center;
  width: 100%;
  padding-top: 10px;
  line-height: 2em;
}

.footer-blog {
  text-align: center;
}

.footer-blog > a > i {
  color: white;
  font-size: 4em;
  padding: 8px;
  text-align: center
}

.img-blog {
  max-width: 30% !important;
  overflow: hidden;
  max-width: 500px;
  max-height: 600px;
  text-align: right;

}

.img-blog img {
  width: 120%;
  transform: translateX(0%);
}

.img-blog a {
  padding: 0px !important;
  font-size: 0.8em;
}


