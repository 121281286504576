@media (max-width: 990px){

    .titleContainer {
        max-height: 400px;
      }
    
    .titleCentered {
        font-size: 3em;
    }

    .landingImg {
        width: 170%;
        height: auto;
    
    }
        
    .titleImg {
        width: 120%;
        height: auto;
    }

    .image-container {
        text-align: center;
        width: 100%;
        position: absolute;
        top: 50px;

    }

    .image-container img {
        width: 83%;
        margin-top: 0%;
    }

    .motto-container h5 {
        font-size: 27px;
        line-height: 36px;
        margin-left: 2%;
        margin-right: 2%;
        margin-top: 2%;
        margin-bottom: 2%;
    }

    .motto-container {
        top: 70%;
      
      }

    .nav-link  {
        padding: 1%;
    }

    #hamburger {
        margin-right: 5%;
    }

    .navbar-brand {
        margin-left: 5%;
    }

    .language {
        flex-direction: row;
        justify-content: center;
        margin-top: 15px;
    }

    .nav-item a:hover {
        background-color: #004E59;
    }
}

@media (max-width: 500px){

    .app_desc p img {
        max-width: 400px!important;
        max-height: 100% !important;
        margin-bottom: 2% !important;
        padding-left: 0% !important;
    }

    .blog-text img {
        max-width: 400px!important;
        max-height: 100% !important;
        margin-bottom: 2% !important;
        padding-left: 0% !important;
        display: none !important;
    }

    .motto-container h5 {
        font-size: 22px;
        line-height: 28px;
    }

    .img-blog a {
        font-size: 0.6em;
      }

    .img-blog img {
        width: 300%; 
        transform: translateX(0%);
    }
}

@media (min-width: 990px) and (max-width: 1400px) {


.app_image {
    width: 120%;
    transform: translateX(-5%);
  }
}

@media (min-width: 1800px){

    .titleContainer {
        max-height: 900px;
      }

    #landing {
        max-height: 900px;
      }

}